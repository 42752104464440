import RouterPage from "./router/RouterPage";
import { ToastContainer } from "react-toastify";
// import "./app.css";

function App() {
	return (
		<div className="">
			<RouterPage />
			<ToastContainer />
		</div>
	);
}

export default App;
